import { Directive } from 'vue';

const vScrollClass: Directive = {
  mounted(el, binding) {
    const options = binding.value || {};

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          el.classList.add(options.className || 'scrolled-into-view');
        } else {
          // el.classList.remove(options.className || 'scrolled-into-view');
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.2,
      }
      
    );

    observer.observe(el);
  },
};

export default vScrollClass;