<template>
  <div class="modal" v-if="showModal">
    <div class="modal__wrap">
      <div class="modal__close" @click="handleShowModal">
        <img src="@/assets/image/icon/close.png" alt="" />
      </div>
      <slot></slot>
      <div v-if="props.navigate">
        <div class="modal__next" @click="onHandeNextClick">
          <img src="@/assets/image/icon/next.png" alt="" />
        </div>
        <div class="modal__prev" @click="onHandePrevClick">
          <img src="@/assets/image/icon/prev.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineExpose, defineProps, defineEmits } from "vue"

const props = defineProps({
    navigate:{
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['handeNextClick','handePrevClick'])

const onHandeNextClick = () => {
    emit('handeNextClick')
}
const onHandePrevClick = () => {
    emit('handePrevClick')
}

const showModal = ref(false);

const handleShowModal = () => {
  showModal.value = !showModal.value;
};
defineExpose({
  handleShowModal,
});
</script>
<style lang="scss" scoped src="@/assets/scss/common/_modal.scss"></style>