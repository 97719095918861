<template>
  <div class="life">
    <div class="life__wrap">
      <BoxCommon
        v-scroll-Class="{ className: 'life__fadeIn' }"
        v-for="(item, index) in products"
        :key="index"
        class="life__box"
        :image="item.avatar"
        :title="item.title"
        @click="handleDetail(item.id, index)"
        :showLink="false"
      ></BoxCommon>
    </div>
  </div>
</template>
<script setup>
import BoxCommon from "@/components/common/box-common.vue"
import vScrollClass from "@/directive/ScrollClass"
import { useRouter } from "vue-router"
import productData from '@/data/dataLiffe.Json'

const router = useRouter()

const products = productData


const handleDetail = (id) => {
    const params = { id: id };
    router.push({ name: 'Detail', params });
}

</script>
<style lang="scss" scoped src="@/assets/scss/construction/_index.scss"></style>