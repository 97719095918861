<template>
  <div class="detail">
    <div class="detail__content container">
      <div class="detail__list">
        <div
          class="detail__list--item"
          v-for="(box, n) in dataDetail.imageLinks"
          :key="n"
          v-ScrollClass="{ className: 'detail__fadeIn' }"
        >
          <BoxCommon
            :showLink="false"
            :image="box"
            @click="showModal(n)"
          ></BoxCommon>
        </div>
      </div>

    </div>
    <ModalCommon ref="refModal" :navigate="false">
      <swiper
        :loop="true"
        :hashNavigation="{
      watchState: true,
    }"
        :pagination="{
      clickable: true,
    }"
        :spaceBetween="10"
        :navigation="true"
        :initialSlide="showImg"
        :thumbs="{ multipleActiveThumbs: false, swiper: thumbsSwiper }"
        :modules="modules"
        class="mySwiper2"
      >
        <swiper-slide
        v-for="(item, i) in dataDetail.imgShow" :key="i">
          <Loadding v-if="loadingImg"></Loadding>
          <img
            loading="lazy"
            :src="require(`@/assets/image/img/${item}`)"
            @load="doneLoadImg"
        /></swiper-slide>
      </swiper>
    </ModalCommon>
  </div>
</template>
<script setup>
import BoxCommon from "@/components/common/box-common.vue";
import ModalCommon from "@/components/common/modal-common.vue";
import vScrollClass from "@/directive/ScrollClass";
import Loadding from "@/components/common/loadding-common.vue"
import {
  defineProps,
  onBeforeMount,
  ref,
} from "vue";
import dataLife from "@/data/dataLiffe.Json";
import { Swiper, SwiperSlide } from 'swiper/vue';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from 'swiper/modules';
const modules = [Navigation, Pagination];


const props = defineProps({
  id: {}
});

const thumbsSwiper = ref(null);
const data = ref({});
const dataDetail = ref({});
const refModal = ref(null);
const showImg = ref(0);
const loadingImg = ref(true);

onBeforeMount(() => {
  window.scrollTo(0, 0);
  data.value = dataLife;

  dataDetail.value = data.value.find((item) => item.id == props.id);
});


const showModal = (index) => {
  thumbsSwiper.value = null
  showImg.value = index

  refModal.value.handleShowModal();
};
const doneLoadImg = () =>{
  loadingImg.value = false;
}
</script>
<style lang="scss" scoped src="@/assets/scss/common/_detail.scss"></style>
