import { createStore } from 'vuex'

export default createStore({
  state: {
    isScreenMobile: false,
    scrollPosition:0
  },
  getters: {
  },
  mutations: {
    SET_IS_SCREEN_MOBILE(state, value) {
      state.isScreenMobile = value;
    },
    SET_SCROLL_POSITION(state, value) {
      state.scrollPosition = value;
    },
  },
  actions: {
  },
  modules: {
  }
})
