import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home/HomeView.vue'
import ConstructionView from '../views/Construction/ConstructionView.vue'
import ContactView from '../views/Contact/ContactView.vue'
import DetailCommon from '../components/common/detail-common.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta:{isPageHome: true}
  },
  {
    path: '/construction',
    name: 'construction',
    component: ConstructionView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/details/:id',
    name: 'Detail',
    component: DetailCommon,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
