<template>
  <div>
    <Loadding v-if="loaddingSite"></Loadding>
    <div v-show="!loaddingSite">
      <Header></Header>
      <router-view />
      <!-- <div class="back-to-top" :class="{'show': showBtn}">
      <button @click="scrollTop"><span>back to top</span></button>
    </div> -->
    </div>
  </div>
</template>
<script setup>
import Header from "@/components/header/_index.vue";
import Loadding from "@/components/common/loadding-common.vue";
import { onBeforeMount, ref } from "vue";
import { useStore } from "vuex";

const showBtn = ref(false);
const loaddingSite = ref(true);
const store = useStore();
onBeforeMount(() => {
  handleResize();
  window.addEventListener("resize", handleResize);
  window.onload = () => {
    loaddingSite.value = false;
  };
});
const handleResize = () => {
  let screenWidth = window.innerWidth;
  if (screenWidth <= 768) {
    store.commit("SET_IS_SCREEN_MOBILE", true);
  } else {
    store.commit("SET_IS_SCREEN_MOBILE", false);
  }
};

window.addEventListener("scroll", () => {
  store.commit("SET_SCROLL_POSITION", window.scrollY);
  if (window.scrollY > 500) {
    showBtn.value = true;
  } else {
    showBtn.value = false;
  }
});
// const scrollTop = () =>{
//   window.scrollTo({ top: 0, behavior: 'smooth' });
// }
</script>
<style lang="scss" src="@/assets/scss/main.scss"></style>
<style lang="scss">
.back-to-top {
  position: fixed;
  bottom: 80px;
  right: 60px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s;
  transform: translateY(50px);
  &.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
  > button {
    background-color: transparent;
    border-radius: 6px;
    border: 3px solid $colorBorder-01;
    width: 50px;
    height: 50px;
    cursor: pointer;
    @include responsive($max: $tablet-width) {
      width: 40px;
      height: 40px;
    }
    > span {
      font-size: 0;
      &::before {
        content: "";
        display: inline-block;
        width: 40%;
        height: 40%;
        border-radius: 4px;
        border-style: solid;
        border-width: 3px;
        border-color: $colorBorder-01 $colorBorder-01 transparent transparent;
        transform: translateY(20%) rotate(-45deg);
        transform-origin: center;
      }
    }
  }
}
</style>
