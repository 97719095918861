<template>
    <div class="contact container">
        <div class="contact--wrap">
            <ul>
                <li>
                    Địa chỉ:
                    <span>- 51 Phan Thúc Trực, Đà Nẵng</span>
                    <span>- 369a Tây Sơn, Quy Nhơn</span>
                </li>
                <li>Số điện thoại (Zalo): 0905 090 090</li>
                <li>Email: dicoldn@gmail.com</li>
            </ul>
        </div>
    </div>
</template>

<script>
</script>

<style lang="scss" scoped src="@/assets/scss/contact/_index.scss"></style>
